import React from "react";
import Layout from "../components/layout";
import HeaderTwo from "../components/header/header-two";
import StickyHeader from "../components/header/sticky-header";
import GalleryPage from "../components/gallery/gallery-page";
import PageHeader from "../components/page-header";
import Footer from "../components/footer";

const Gallery = () => {
  return (
    <Layout pageTitle="NASZ WARSZTAT - Auto Serwis - Tomasz Jatkowski">
      <HeaderTwo />
      <StickyHeader />
      <PageHeader title="NASZ WARSZTAT" crumbTitle="Nasz Warsztat" />
      <GalleryPage />
      <Footer />
    </Layout>
  );
};

export default Gallery;
